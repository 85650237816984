.login-as-view-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.login-as-view-logo {
    width: 250px;
    margin-bottom: 2vw;
}

.login-as-view-cards {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.login-as-view-user-txt {
    font-size: 25px;
    font-weight: 700;
    color: black;
    margin-bottom: 40px;
    text-align: center;
}

.login-as-view-logout-btn {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    color: black;
}

.login-as-view-logout-btn:hover {
    text-decoration: underline;
}

@media (max-width: 600px)  {
    .login-as-view-main-container {
        display: flex;
        flex-direction: column;
    }

    .login-as-view-cards {
        display: flex;
        flex-direction: column;
    }

    .login-as-view-logo {
        margin-bottom: 5vh;
    }

    .login-as-view-user-txt {
        font-size: 18px;
        max-width: 80vw;
    }
}