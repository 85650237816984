.All-questions-entry-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    height: 50px;
}

.All-questions-left-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
   
}

.All-questions-entry-bookmark-icon{
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.All-questions-entry-expand-icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
    cursor: pointer;
}

.All-questions-entry-go-icon {
    width: 40px;
    height: auto;
    margin-right: 15px;
    cursor: pointer;
}

.All-questions-entry-question-number {
    font-size: 16px;
    color: black;
    font-weight: 400;
    margin-right: 10px;
}

.All-questions-answer-label {
    background: rgba(249, 156, 156, 0.2);
    color: red;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 12px;
}

.All-questions-answer-label.answered {
    background: rgba(173, 249, 156, 0.2);
    color:green;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
}