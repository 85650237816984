:root {
    --green-main: #b1ff2e;


    --main-bg-color: #000000;
    --main-text-color: white;

    --nav-menu-color: white;
    --nav-menu-color-hover: var(--green-main);

    --social-icons-color-hover: var(--green-main) ;
    --social-icons-color: black;

    --titles-color: var(--green-main);
    --subtitles-color:white;
    --titles-color-secundary: black;

    --main-btn-bg-color: black;
    --secundary-btn-bg-color: var(--green-main);
    --main-btn-txt-color: var(--green-main);
    --secundary-btn-txt-color:white;
    --main-btn-border-color: var(--green-main);

    --btn-bg-color-hover: var(--green-main);
    --main-btn-txt-color-hover:black;
    --main-btn-border-color-hover: var(--green-main);

    --main-box-border-color:white;
}

.company-name-label {
    color: var(--titles-color);
    font-weight: 700; 
}

.company-highlights {
    color: var(--titles-color);
    font-weight: 700; 
}