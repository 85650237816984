.quiz-app-view-main-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
}

.quiz-app-view-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
}

.app-quiz-app-see-all-questions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 1000px;
}

.quiz-app-see-all-questions-left-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz-app-see-all-questions-icon {
    height: 20px;
    margin: 0 5px 0 0 ;
}

.quiz-app-see-all-questions-txt {
    font-size: 15px;
    color: black;
    cursor: pointer;
}

.quiz-app-see-all-questions-txt:hover {
    text-decoration: underline;
}

.quiz-app-timer-container {
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 0 10px 0 10px;
}

.quiz-app-timer-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quiz-app-timer-txt {
    font-size: 18px;
}

.quiz-app-timer-time {
    font-size: 15px;
    font-weight: 400;
}

.app-quiz-timer-progress-bar-container {
    background-color: gray;
    width: 100%;
    border-radius: 15px;
    margin: 0 0 10px 0;
}

.app-quiz-timer-progress-bar{
    background-color: green;
    padding: 3px;
    border-radius: 15px;
}

.quiz-app-question-container {
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
}

.quiz-app-question-container-header {
    display: flex;
    flex-direction: row;
    background-color: lightgray;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    border-radius: 10px 10px 0 0;
}

.quiz-app-question-bookmark-icon {
    width: 30px;
    cursor: pointer;
}

.quiz-app-question-main-container {
    min-width: 1000px;
    min-height: 200px;
    border: 1px solid lightgray;
    border-radius: 0 0 10px 10px;
    padding: 0 10px 0 10px;
}

.quiz-app-question-description {
    font-size: 18px;
}

.quiz-app-question-option-container {
    font-size: 18px;
    margin: 5px 0 0 0;
}

.quiz-app-question-option {
    font-size: 18px;
}

.quiz-app-view-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quiz-app-view-btn {
    margin-top: 40px;
    padding: 10px 30px 10px 30px;
    font-size: 18px;
    font-weight:700;
    color: var(--main-btn-txt-color-hover);
    background-color: var(--green-main);
    border-style: none;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-app-view-btn:hover {
    color: var(--green-main);
    background-color: var(--main-bg-color);
}

.quiz-app-view-btn-finish {
    margin-top: 40px;
    padding: 10px 30px 10px 30px;
    font-size: 18px;
    font-weight:700;
    max-width: 200px;
    color: white;
    background-color: red;
    border-style: none;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-app-view-btn-finish:hover {
    color: var(--green-main);
    background-color: var(--main-bg-color);
}

