.results-view-final-note-container {
    background-color: rgba(251, 246, 97, 0.1);
    border-left: 4px solid orange;
    border-radius: 5px;
    width: 60vw;
    margin-top: 40px;
    margin-bottom: 30px;
}

.results-view-final-note-inner-container {
    display: flex;
    flex-direction: row;
    align-items:first baseline;
    margin: 10px;
}

.results-view-final-note-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.results-view-final-note-box {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.results-view-final-note-text {
    font-size: 14px;
    font-weight: 400;
    color: black;
    padding: 2px 0;
    margin: 0;
}

.results-view-final-note-label {
    font-weight: 700;
   
}










.results-view-main-container {
    display: flex;
    align-items: center;
    width: 60vw;
    /* min-height: 50vh; */
    border: 1px solid lightgray;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 40px;
}

.results-view-inner-container {
    margin: 30px;
}

.results-view-container-title {
    padding-top: 0;
    margin-top: 0;
    font-size: 30px;
    font-weight: 700;
}

.results-view-results-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.results-view-box-title {
    font-size: 18px;
    font-weight: 700;
    min-width: 200px;
    padding: 0;
    margin: 0;
}

.results-view-box-text {
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    margin: 5px;
}

.results-view-box-items {
    display: flex;
    flex-direction: column;
    margin: 50px 0 50px 0;
}

.results-view-box-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.results-view-box-feedback-container {
    background-color: rgba(211, 211, 211, 0.3);
    border-left: 4px solid orange;
    border-radius: 5px;
}

.results-view-box-feedback-container-success {
    background-color: rgba(211, 211, 211, 0.3);
    border-left: 4px solid rgb(46, 255, 88);
    border-radius: 5px;
}

.results-view-box-feeback-inner-container {
    margin: 10px;
}





.results-view-continue-container {
    display: flex;
    align-items: center;
    width: 60vw;
    /* min-height: 50vh; */
    border: 1px solid lightgray;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 30px;
}

.results-view-continue-inner-container {
    margin: 20px;
}

.results-view-continue-btn {
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    background-color: grey;
    color: black;
    border-radius: 5px;
    border-style: none;
}

.results-view-continue-btn:hover {
    background-color: grey;
    color: white;
}