.result-question-detail-main-container {
    display: flex;
    align-items: center;
    width: 60vw;
    /* min-height: 50vh; */
    border: 1px solid lightgray;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 40px;
}

.results-question-detail-inner-container {
    display: flex;
    flex-direction: column;
    align-items:first baseline;
    margin: 30px;
    width: 100%;
}

.results-question-detail-question-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.results-question-detail-question-header-left {
    display: flex;
    flex-direction: row;
}

.result-question-detail-question-number {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.result-question-detail-bookmark-icon {
    width: 20px;
    height: auto;
    margin-left: 10px; 
}

.results-question-detail-question-header-right {
    display: flex;
    flex-direction: row;
}

.result-question-detail-question-result {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: green;
}

.result-question-detail-question-result-wrong {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: red;
}

.result-question-detail-question-description {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
    padding-bottom: 10px;
}

.result-question-detail-question-options {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
    margin-left: 5px;
}

.result-question-detail-option-container {
    display: flex;
    flex-direction: row;
}