.All-questions-main-container {
    background: rgba(170, 170, 170, 0.9);
    position: fixed;
    top: 0;
    left: -10px;
    right: 0;
    bottom: -10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 10;
}

.All-questions-inner-container {
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.All-questions-cancel-header-container {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inscription-detail-btn-cancel {
    background-color: white;
    border: none;
    cursor: pointer;
}

.All-questions-close-icon {
    width: 40px;
    height: auto;
} 

.All-questions-nav-btns-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    height: 60px;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
}

.All-questions-nav-btns-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
}

.All-questions-nav-btns-bookmark-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.All-questions-nav-btns-text-left {
    font-size: 20px;
    font-weight: 400;
    color: grey;
    margin-left: 15px;
    cursor: pointer;
}

.All-questions-nav-btns-text-left.active {
    color: var(--green-main);
    border-bottom: 1px solid var(--green-main);
}

.All-questions-nav-btns-text-right {
    font-size: 20px;
    font-weight: 400;
    color: grey;
    margin-left: 5px;
    cursor: pointer;
}

.All-questions-nav-btns-text-right.active {
    color: var(--green-main);
    border-bottom: 1px solid var(--green-main);
}

.All-questions-nav-btns-bookmark-count {
    padding: 2px 5px 2px 5px;
    border: 1px solid lightgrey;
    border-radius: 50%;
    font-size: 15px;
    margin-left: 10px;
    color: grey;
}

.Al-questions-nav-btns-bookmark-icon {
    margin-left: 10px;
    width: 30px;
    height: auto;
}

.All-questions-expand-icon {
    width: 30px;
    height: auto;
    padding: 10px;
    margin-right: 2px;
    cursor: pointer;
}

.All-questions-expand-icon:hover {
    background-color:rgba(170, 170, 170, 0.1);
    border-radius: 50%;
}