.login-as-card-main-container {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 20vw;
    align-items: center;
    justify-content: center;
    background: black;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 2px solid rgba(255, 255, 255, 0.3);
    margin: 2vw;
}

.login-as-card-main-container:hover {
    border: 2px solid var(--main-btn-border-color-hover);
}

.login-as-card-title {
    font-size: 1.8rem;
    color: var(--green-main);
}

.login-as-card-logo {
    height: 10vw;
    width: auto;
}

@media (max-width: 600px)  {
    .login-as-card-main-container {
        display: flex;
        flex-direction: column;
        width: 60vw;
        height: 60vw;
        margin: 2vw;
    }
}