.my-assessments-view-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items:center;
    width: 100vw;
    justify-content: center;
    background-color: white;
}

.my-assessments-view-cards-container {
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items:center;
    width: 100%;
    justify-content: center;
}

.my-assessments-view-welcome-title {
    margin-top: 80px;
    color: black;
    font-weight: 700;
    font-size: 25px;
}

.my-assessments-view-welcome-subtitle {
    color:  black;
    font-weight: 400;
    font-size: 20px;
}