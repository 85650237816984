.my-assessments-card-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 15vw;
    min-height: 10vh;
    height: fit-content;
    overflow: hidden;
    background: var(--main-btn-border-color-hover) ;
    border-radius: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 2vw 5vh 2vw;
    cursor: pointer;
}

.my-assessments-card-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 0 5px 0;
}

.my-assessments-card-img {
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1vh;
}

.my-assessments-card-title {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: black;
    margin: 1vh 2vw 1vh 2vw;
}

.my-assessments-card-main-container:hover {
    border: 1px solid var(--main-btn-border-color-hover);
    background-color: black;
}

.my-assessments-card-title:hover {
    color: var(--main-btn-border-color-hover);
}