.quiz-app-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quiz-app-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
}

.quiz-app-logo {
    width: 300px;
    height: auto;
}

.quiz-app-quiz-title {
    font-size: 25px;
    font-weight: 700;
}

.quiz-app-quiz-instructions-container {
    margin-top: 30px;
    background-color: lightgray;
    padding: 10px 20px 20px 20px;
    border-radius: 10px;
    min-width: 1000px;
    min-height: 200px;
}

.quiz-app-quiz-subtitle {
    font-size: 20px;
    font-weight: 700;
}

.quiz-app-instruction-item {
    font-size: 18px;
    font-weight: 400;
    padding: 2px;
}

.quiz-app-instruction-label {
    font-weight: bold;
}

.quiz-app-instruction-btn {
    margin-top: 40px;
    padding: 10px 30px 10px 30px;
    font-size: 18px;
    font-weight:700;
    color: var(--main-btn-txt-color-hover);
    background-color: var(--green-main);
    border-style: none;
    border-radius: 5px;
    cursor: pointer;
}

.quiz-app-instruction-btn:hover {
    color: var(--green-main);
    background-color: var(--main-bg-color);
}

.quiz-app-copy-rights-text {
    font-size: 18px;
    font-weight: 400;
    padding: 2px;
}

.quiz-app-copy-rights-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}